<template>
  <v-container class="content-container px-0">
    <forgot-password-dialog ref="forgotPasswordDialog"></forgot-password-dialog>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="10" sm="8" md="6" lg="6" class="form-col">
        <v-row class="text-center">
          <v-col cols="12" class="text-h3">{{ $t("signin.title") }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="authentication.username"
              :rules="usernameRules"
              :label="$t('signin.form.email')"
              placeholder=" "
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="authentication.password"
              :rules="passwordRules"
              :label="$t('signin.form.password')"
              placeholder=" "
              type="password"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn
              id="signin-btn"
              @click="signin"
              color="green"
              large
              block
              :disabled="isSigningIn"
            >
              <LoadingSpinnerInline v-if="isSigningIn"></LoadingSpinnerInline>
              <template v-else>{{ $t("signin.form.signinButton") }}</template>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <v-btn class="forgot-btn" to="/registration" color="primary" text>
              {{ $t("signin.form.register") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn class="forgot-btn" @click="forgotUsername" color="primary" text>
              {{ $t("signin.form.forgotUsernameButton") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn class="forgot-btn" @click="openForgotPassword" color="primary" text>
              {{ $t("signin.form.forgotPasswordButton") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn class="forgot-btn" :to="{path:'donation'}" color="primary" outlined large>
              {{ $t("signin.form.donateButton") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import Bus from "@/bus";
import UserApi from "@/api/UserApi";
import LoadingSpinnerInline from "@/components/Layout/LoadingSpinnerInline";
import ForgotPasswordDialog from "@/components/Signin/ForgotPasswordDialog";

export default {
  components: {
    LoadingSpinnerInline,
    ForgotPasswordDialog,
  },
  beforeRouteEnter(to, from, next) {
    if (
      getParameterByName("orderID") != null &&
      getParameterByName("SHASIGN") != null &&
      getParameterByName("amount") != null &&
      getParameterByName("STATUS") != null
    ) {
      let protocol = window.top.location.protocol;
      let host = window.top.location.host;

      window.top.location.href = `${protocol}//${host}/#/donation/?orderID=${getParameterByName(
        "orderID"
      )}&currency=${getParameterByName("currency")}&amount=${getParameterByName(
        "amount"
      )}&PM=${getParameterByName("PM")}&ACCEPTANCE=${getParameterByName(
        "ACCEPTANCE"
      )}&STATUS=${getParameterByName("STATUS")}&CARDNO=${getParameterByName(
        "CARDNO"
      )}&ED=${getParameterByName("ED")}&CN=${getParameterByName("CN")}&TRXDATE=${getParameterByName(
        "TRXDATE"
      )}&PAYID=${getParameterByName("PAYID")}&NCERROR=${getParameterByName(
        "NCERROR"
      )}&BRAND=${getParameterByName("BRAND")}&IP=${getParameterByName(
        "IP"
      )}&SHASIGN=${getParameterByName("SHASIGN")}`;
    }

    function getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    next();
  },
  data: () => ({
    authentication: {
      username: null,
      password: null,
    },
    isSigningIn: false,

    userApi: new UserApi(),
  }),
  computed: {
    usernameRules() {
      return [(v) => !!v || this.$t("signin.rule.usernameRule")];
    },
    passwordRules() {
      return [(v) => !!v || this.$t("signin.rule.passwordRule")];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.params.email) {
        this.authentication.username = this.$route.params.email;
      }
    },
    async signin() {
      this.isSigningIn = true;
      this.authentication.username = await this.authentication.username.toLowerCase();
      const result = await this.userApi.authenticate(this.authentication);
      this.isSigningIn = false;

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }
      if (this.$store.getters.isLoggedIn) this.$router.push("/profile");
    },
    async openForgotPassword() {
      this.$refs.forgotPasswordDialog.open().then((resolve) => {});
    },
    async forgotUsername() {
      this.$router.push("/registration");
    },
  },
};
</script>

<style lang="scss" scoped>
$font-color: #444444;

.text-h3 {
  color: $font-color;
}
.form-col > .row:not(:first-child) {
  padding-top: 1rem;
}
::v-deep .v-text-field--outlined {
  .v-input__slot {
    min-height: 5.5rem;
  }
  label {
    color: $font-color;
    font-size: 1.5rem;
    min-height: 2rem;
  }
  input {
    min-height: 4.5rem;
    margin-top: 1rem;
    font-size: 2rem;
    color: $font-color;
  }
}
#signin-btn {
  color: white;
  font-size: 1.5rem;
  height: 4rem !important;
}
.forgot-btn {
  font-size: 1.1rem;
}
</style>
