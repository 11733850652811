<template>
  <v-dialog v-model="dialog" max-width="675px" persistent>
    <v-card>
      <v-card-title>{{ $t("signin.forgotPasswordDialog.title") }}</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="forgotPasswordForm">
          <v-row row text-xs-center>
            <v-col cols="12">
              {{ $t("signin.forgotPasswordDialog.messsage") }}
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.trim="email"
                :rules="rules.required"
                :label="$t('signin.form.email')"
                placeholder=" "
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row class="mx-0 justify-end row align-center">
          <v-col cols="8" class="d-flex justify-end">
            <a style="text-decoration: none" class="align-center mr-4" @click="close" x-large dark>
              {{ $t("signin.forgotPasswordDialog.button.cancel") }}
            </a>
          </v-col>
          <v-col cols="4" class="justify-end d-flex"
            ><v-btn color="success" class="ml-0 mr-0" @click="submitForgotPassword" x-large dark>
              {{ $t("signin.forgotPasswordDialog.button.resetPassword") }}
            </v-btn></v-col
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Bus from "@/bus";
import PasswordApi from "@/api/PasswordApi";
export default {
  data: () => ({
    valid: false,
    dialog: false,
    resolve: null,
    reject: null,
    email: "",

    passwordApi: new PasswordApi(),
  }),
  computed: {
    rules() {
      return {
        required: [(v) => !!v || this.$t("signin.forgotPasswordDialog.rule.required")],
      };
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.email = "";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async submitForgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        const resultPassword = await this.passwordApi.forgotPassword(this.email);

        if (resultPassword.error) {
          Bus.$emit("alert", "error", resultPassword.error);
          return;
        }
        if (!resultPassword.success) {
          Bus.$emit(
            "alert",
            "error",
            this.$t("signin.forgotPasswordDialog.errorMessage.passwordResetNotSent")
          );
          return;
        }
        if (resultPassword.success == true) {
          Bus.$emit(
            "alert",
            "success",
            this.$t("signin.forgotPasswordDialog.successMessage.passwordLinkSent")
          );
          this.dialog = false;
          this.resolve(true);
        }
      }
    },
    close() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>
<style scoped>
/* ::v-deep .v-text-field input {
  font-size: 45px;
}
::v-deep .v-input input {
  max-height: 50px;
} */
.v-dialog {
  position: absolute;
  top: 0;
  overflow: hidden;
}
/* height of text field slot */
/* .v-input.coilHeatTextField >>> .v-input__slot div.v-text-field__slot {
  height: 70px !important;
} */
/* textfield input size area */
/* /deep/.v-input input {
  max-height: 80px !important;
} */
/* textfield label font size */
/* .v-input.coilHeatTextField >>> .v-label {
  font-size: 25px !important;
} */
/* area for the textfield label */
/* /deep/ .v-input .v-label {
  height: 100px !important;
} */
</style>
